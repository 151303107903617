<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">
				<el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增</el-button>

				<el-button size="small" v-if="$store.state.pmid!=2" icon="el-icon-refresh" @click="tongbuCaidan" type="primary">同步</el-button>
			</div>
			<div style="padding-top: 14px;">
				
				<div style=" width: 200px;  margin-top:-10px;">
                    <div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
                        <img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
                    </div>
                    <div style="margin-left: 50px; color:#535353; font-size:20px !important">系统菜单</div>
                </div>
				
			</div>

		</div>
		<div class="bcontent" >
			<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border
				stripe default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
				<el-table-column type="index" label="序号" width="80">
				</el-table-column>
				<el-table-column prop="title" label="名称" min-width="180">
					<template slot-scope="scope">
						<i v-if="scope.row.icon" :class="scope.row.icon" /> {{scope.row.title}}
					</template>
				</el-table-column>

				<el-table-column prop="id" label="类型">
					<template slot-scope="scope">
						{{scope.row.mtype=='page'?'页面':'按钮'}}
					</template>
				</el-table-column>
				<el-table-column prop="path" label="路径">
				</el-table-column>
				<!--                        <el-table-column-->
				<!--                            prop="status" label="状态">-->
				<!--                            <template slot-scope="scope">-->
				<!--                                <span>{{scope.row.status?'显示':'隐藏'}}</span>-->
				<!--                            </template>-->
				<!--                        </el-table-column>-->

				<el-table-column prop="sort" label="排序" width="100">
				</el-table-column>
				<el-table-column prop="id" label="状态" width="100">
					<template slot-scope="scope">
						<el-tag type="warning" v-if="scope.row.status==0">禁用</el-tag>
						<el-tag type="success" v-else>启用</el-tag>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="180">
					<template slot-scope="scope">
						<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
							iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
							<a slot="reference" size="small"
								style="color: red;font-size: 12px;margin-right: 10px;">删除</a>
						</el-popconfirm>
						<el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>
						<el-button @click="handleAdd(scope.row)" type="text" size="small">添加下级</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :title="modalTitle" class="cus_dialog" :visible.sync="isShowModal" :close-on-click-modal="false"
			:destroy-on-close="true">
			<el-form :model="formData" :rules="rules" ref="adminUserInfo" label-width="120px"
				style="max-height: 450px;overflow-y: auto;min-height: 300px;">
				<el-form-item  label="上级菜单" prop="pid">
					<el-cascader ref="firstCascader" :options="tableData" 
						:props="defaultProps" v-model="formData.pid" :show-all-levels="false"
						@change="handleCascaderChange" placeholder="请选择" filterable style="width: 50%"></el-cascader>
				</el-form-item>
				<el-form-item label="菜单名称" prop="title">
					<el-input v-model.trim="formData.title" style="width: 50%" />
				</el-form-item>
				<el-form-item label="菜单类型" prop="mtype">
					<el-select v-model="formData.mtype" @change="$forceUpdate()" placeholder="请选菜单类型"
						style="width: 50%">
						<el-option label="页面" value="page">页面</el-option>
						<el-option label="按钮" value="button">按钮</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="菜单图标" prop="icon">
					<el-select v-model="formData.icon" placeholder="请选择" filterable  style="width: 50%">
						<el-option v-for="(item,index) in iconGroup" :key="index" :label="item" :value="item">
							<template slot-scope="scope">
								<span class="svg-container">
									<i :class="item" />
								</span>
								<span style="margin-left: 5px;">{{item}}</span>
							</template>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
						controls-position="right" />
				</el-form-item>
				<el-form-item label="路径" prop="path">
					<el-input v-model.trim="formData.path" style="width: 50%" />
				</el-form-item>
				<el-form-item label="是否启用" prop="mtype">
					<el-select v-model="formData.status" placeholder="请选是否启用"
						style="width: 50%">
						<el-option label="启用" :value="1">启用</el-option>
						<el-option label="禁用" :value="0">禁用</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<el-row>
				<el-col :span="22" style="text-align: right;">
					<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
					<el-button size="small" type="primary" @click="submitForm('adminUserInfo')">保存</el-button>
				</el-col>
			</el-row>
		</el-dialog>

	</div>
</template>

<script>
	import api from '@/api/api';

	export default {
		name: 'Menu',
		data() {
			return {
				visible: false,
				isShowModal: false,
				modalTitle: '添加',
				formData: {},
				defaultProps: {
					label: 'title',
					value: 'id',
					children: 'children',
					emitPath: false,
					checkStrictly: true
				},
				//table
				tabMapOptions: [{
					label: '菜单列表',
					key: 'CN'
				}, ],
				activeName: 'CN',
				tableData: [],
				iconGroup: `el-icon-delete-solid
					el-icon-delete
					el-icon-s-tools
					el-icon-user-solid
					el-icon-setting
					el-icon-user
					el-icon-phone
					el-icon-phone-outline
					el-icon-more
					el-icon-more-outline
					el-icon-star-on
					el-icon-star-off
					el-icon-s-goods
					el-icon-goods
					el-icon-warning
					el-icon-warning-outline
					el-icon-question
					el-icon-info
					el-icon-circle-plus
					el-icon-remove
					el-icon-success
					el-icon-error
					el-icon-zoom-in
					el-icon-zoom-out
					el-icon-remove-outline
					el-icon-circle-plus-outline
					el-icon-circle-check
					el-icon-circle-close
					el-icon-s-help
					el-icon-help
					el-icon-minus
					el-icon-plus
					el-icon-check
					el-icon-close
					el-icon-picture
					el-icon-picture-outline
					el-icon-picture-outline-round
					el-icon-upload
					el-icon-upload2
					el-icon-download
					el-icon-camera-solid
					el-icon-camera
					el-icon-video-camera-solid
					el-icon-video-camera
					el-icon-message-solid
					el-icon-bell
					el-icon-s-cooperation
					el-icon-s-order
					el-icon-s-platform
					el-icon-s-fold
					el-icon-s-unfold
					el-icon-s-operation
					el-icon-s-promotion
					el-icon-s-home
					el-icon-s-release
					el-icon-s-ticket
					el-icon-s-management
					el-icon-s-open
					el-icon-s-shop
					el-icon-s-marketing
					el-icon-s-flag
					el-icon-s-comment
					el-icon-s-finance
					el-icon-s-claim
					el-icon-s-custom
					el-icon-s-opportunity
					el-icon-s-data
					el-icon-s-check
					el-icon-s-grid
					el-icon-menu
					el-icon-share
					el-icon-d-caret
					el-icon-caret-left
					el-icon-caret-right
					el-icon-caret-bottom
					el-icon-caret-top
					el-icon-bottom-left
					el-icon-bottom-right
					el-icon-back
					el-icon-right
					el-icon-bottom
					el-icon-top
					el-icon-top-left
					el-icon-top-right
					el-icon-arrow-left
					el-icon-arrow-right
					el-icon-arrow-down
					el-icon-arrow-up
					el-icon-d-arrow-left
					el-icon-d-arrow-right
					el-icon-video-pause
					el-icon-video-play
					el-icon-refresh
					el-icon-refresh-right
					el-icon-refresh-left
					el-icon-finished
					el-icon-sort
					el-icon-sort-up
					el-icon-sort-down
					el-icon-rank
					el-icon-loading
					el-icon-view
					el-icon-c-scale-to-original
					el-icon-date
					el-icon-edit
					el-icon-edit-outline
					el-icon-folder
					el-icon-folder-opened
					el-icon-folder-add
					el-icon-folder-remove
					el-icon-folder-delete
					el-icon-folder-checked
					el-icon-tickets
					el-icon-document-remove
					el-icon-document-delete
					el-icon-document-copy
					el-icon-document-checked
					el-icon-document
					el-icon-document-add
					el-icon-printer
					el-icon-paperclip
					el-icon-takeaway-box
					el-icon-search
					el-icon-monitor
					el-icon-attract
					el-icon-mobile
					el-icon-scissors
					el-icon-umbrella
					el-icon-headset
					el-icon-brush
					el-icon-mouse
					el-icon-coordinate
					el-icon-magic-stick
					el-icon-reading
					el-icon-data-line
					el-icon-data-board
					el-icon-pie-chart
					el-icon-data-analysis
					el-icon-collection-tag
					el-icon-film
					el-icon-suitcase
					el-icon-suitcase-1
					el-icon-receiving
					el-icon-collection
					el-icon-files
					el-icon-notebook-1
					el-icon-notebook-2
					el-icon-toilet-paper
					el-icon-office-building
					el-icon-school
					el-icon-table-lamp
					el-icon-house
					el-icon-no-smoking
					el-icon-smoking
					el-icon-shopping-cart-full
					el-icon-shopping-cart-1
					el-icon-shopping-cart-2
					el-icon-shopping-bag-1
					el-icon-shopping-bag-2
					el-icon-sold-out
					el-icon-sell
					el-icon-present
					el-icon-box
					el-icon-bank-card
					el-icon-money
					el-icon-coin
					el-icon-wallet
					el-icon-discount
					el-icon-price-tag
					el-icon-news
					el-icon-guide
					el-icon-male
					el-icon-female
					el-icon-thumb
					el-icon-cpu
					el-icon-link
					el-icon-connection
					el-icon-open
					el-icon-turn-off
					el-icon-set-up
					el-icon-chat-round
					el-icon-chat-line-round
					el-icon-chat-square
					el-icon-chat-dot-round
					el-icon-chat-dot-square
					el-icon-chat-line-square
					el-icon-message
					el-icon-postcard
					el-icon-position
					el-icon-turn-off-microphone
					el-icon-microphone
					el-icon-close-notification
					el-icon-bangzhu
					el-icon-time
					el-icon-odometer
					el-icon-crop
					el-icon-aim
					el-icon-switch-button
					el-icon-full-screen
					el-icon-copy-document
					el-icon-mic
					el-icon-stopwatch
					el-icon-medal-1
					el-icon-medal
					el-icon-trophy
					el-icon-trophy-1
					el-icon-first-aid-kit
					el-icon-discover
					el-icon-place
					el-icon-location
					el-icon-location-outline
					el-icon-location-information
					el-icon-add-location
					el-icon-delete-location
					el-icon-map-location
					el-icon-alarm-clock
					el-icon-timer
					el-icon-watch-1
					el-icon-watch
					el-icon-lock
					el-icon-unlock
					el-icon-key
					el-icon-service
					el-icon-mobile-phone
					el-icon-bicycle
					el-icon-truck
					el-icon-ship
					el-icon-basketball
					el-icon-football
					el-icon-soccer
					el-icon-baseball
					el-icon-wind-power
					el-icon-light-rain
					el-icon-lightning
					el-icon-heavy-rain
					el-icon-sunrise
					el-icon-sunrise-1
					el-icon-sunset
					el-icon-sunny
					el-icon-cloudy
					el-icon-partly-cloudy
					el-icon-cloudy-and-sunny
					el-icon-moon
					el-icon-moon-night
					el-icon-dish
					el-icon-dish-1
					el-icon-food
					el-icon-chicken
					el-icon-fork-spoon
					el-icon-knife-fork
					el-icon-burger
					el-icon-tableware
					el-icon-sugar
					el-icon-dessert
					el-icon-ice-cream
					el-icon-hot-water
					el-icon-water-cup
					el-icon-coffee-cup
					el-icon-cold-drink
					el-icon-goblet
					el-icon-goblet-full
					el-icon-goblet-square
					el-icon-goblet-square-full
					el-icon-refrigerator
					el-icon-grape
					el-icon-watermelon
					el-icon-cherry
					el-icon-apple
					el-icon-pear
					el-icon-orange
					el-icon-coffee
					el-icon-ice-tea
					el-icon-ice-drink
					el-icon-milk-tea
					el-icon-potato-strips
					el-icon-lollipop
					el-icon-ice-cream-square
					el-icon-ice-cream-round`,
				rules: {
					title: [{
						required: true,
						message: '请输入菜单名称',
						trigger: 'blur'
					}, ],
				},
			}
		},
		watch: {
			'formData.pid'() {
				if (this.$refs.firstCascader) {
					this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
				}
			},
		},
		mounted() {
			this.iconGroup = this.iconGroup.replace(/\t/g,''). split('\n')
			this.getList();
		},
		methods: {
			getList() {
				let _this = this
				this.$http.post(api.menuList).then(res => {
					if (res.data && res.data.length > 0) {
						_this.deleteEmptyGroup(res.data).then(result => {
							_this.tableData = result
						})
					} else {
						this.$message.error(res.msg)
					}
				})
			},

			tongbuCaidan(){
				this.$http.post("/api/initMenu",{target_pmid:2}).then(res => {
					this.getList()
				})
			},
			// 处理没有children的分组
			deleteEmptyGroup(treeData) {
				return new Promise(resolve => {
					function traversal(data) {
						data.map(item => {
							for (let info in item) {
								if (item['children']) {
									if (item['children'].length > 0) {
										traversal(item['children'])
									}
									if (item['children'].length == 0) {
										delete item['children']
									}
								}
							}
						})
					}

					traversal(treeData)
					resolve(treeData)
				})
			},

			formAdd() {
				this.isShowModal = true;
				this.formData = {}
				this.formData = {
					pid: 0,
					status: true,
					sort: 1,
				}
				this.modalTitle = '添加'
			},
			handleAdd(row) {
				this.isShowModal = true;
				this.formData = {}
				this.formData = {
					pid: row.id,
				}
				this.modalTitle = '添加'
			},
			handleModify(row) {
				this.isShowModal = true;
				this.modalTitle = '修改';
				this.formData = row
			},
			handleDel(row) {
				this.$http.post(api.menuDel, {
					id: row.id
				}).then(res => {
					// if (res.code == 200) {
					// 	this.$message.success(res.msg)
					// 	this.getList();
					// }
					this.$message.success('删除成功')
					this.getList();
				})
			},
			handleCascaderChange(e) {
				console.log(1, e)
			},
			//开关
			switchChange(e) {
				this.formData.status = e
			},

			submitForm(formName) {
				let _this = this
				_this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post(api.menuSave, this.formData).then(res => {
							this.$message.success('保存成功')
							this.getList();
							this.isShowModal = false;
							return
							if (res.code == 200) {
								this.$message.success(res.msg)
								this.getList();
								this.isShowModal = false;
							} else {
								this.$message.error(res.msg)
							}
						})
					}
				})
			}
		}
	}
</script>
<style scoped lang="less" type="text/less">

	.Menu {
    height: 100%;

    .el-row,.el-row .el-col{
        height: 100%;
    }
    .el-tabs{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .el-tabs__content{
        flex: 1;
        .el-tab-pane{
            height: 100%;
        }
    }
}
</style>
